<template>
    <div class="dashboard-search-card-container ">
      <le-card width="100%" height="200px" >
        <template>
          <div class="container">
            <div class="title-box">
              <div class="card-title" style="padding-top: 0;"  >我的主播概况</div>
            </div>

            <div class="tips-box">统计时间：2022-07-25 00:00~2022-07-26 00:00(今日数据有一定延迟，以次日结果为准)</div>
            <div class="card-box">
              <card  
                title="总流水"
                num="10000"
                tips="时间段总交易流水"
                unit="元"
              />
              <card 
                title="开播主播数"
                num="10000"
                tips="时间段旗下主播开播数量"
                unit="人"
              />
            </div>
          </div>
        </template>
      </le-card>
    </div>
</template>

<script>
import tool from '@/utils/tool'
import card from './card.vue'
export default {
  components: { 
    card
  },
  data() {
    return {
      search:''
    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {
    searchClick(){
      console.log(this.search)
      if(!this.search){
        this.$message.error('请填入主播UID');
        return false
      }

      tool.$emit('searchOut', this.search)
    },
    changeSearch(){
      console.log('123')
      if(!this.search){
        tool.$emit('clearOut')
      }
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-search-card-container {
.container{
  position: relative;
  .title-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  .tips-box{
    margin-left: 30px;
    font-size: 12px;
    color: #aaa;
  }

  .card-box{
    display: flex;
    margin-top: 10px;
  }
}
}


@media screen and(max-width: 750px) {
.dashboard-search-card-container {
.container{
  .title{
    padding-top: 10px;
    margin-left: 20px;
    font-size: 16px;
  }
}
}
}

</style>
